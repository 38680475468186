import { initializeApp } from "firebase/app";
import {getDatabase} from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyALT4H42eiKZOV0ezPwC1cTpOXZbeuuuaM",
  authDomain: "last-notarius-react-7f799.firebaseapp.com",
  databaseURL: "https://last-notarius-react-7f799-default-rtdb.firebaseio.com",
  projectId: "last-notarius-react-7f799",
  storageBucket: "last-notarius-react-7f799.appspot.com",
  messagingSenderId: "661071420977",
  appId: "1:661071420977:web:6ecef3b240310d833a3f10"
};


const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);