// import datanot from "../../datanot.json";
 
//  console.log(datasNotar);

const NotarialActs = () => {
  // const datasNotar = datanot.Notar
  // console.log(datasNotar);
 
  // const {
  //   id,
  //   FIO,
  //   NAME_OBJ,
  //   Kind,
  //   CONTACTS,
  //   LICENSE,
  //   Region,
  //   Areal,
  //   City,
  //   FullAddress,
  //   ShortAddress,
  //   Latitude,
  //   Longitude,
  
  //   title,
  //   description,
  //   price,
  //   thumbnail,
  //   rating,
  //   brand,
  //   category,
  // } = datasNotar
 
  return (
    <section class="bg-white mt-[-72px] text-center text-xl font-semibold h-[calc(100vh-200px)] w-full py-10">
    <div class="px-10 " >
   <h2 class=" border__bottom pb-5">Нотаріальні діяння
</h2>
   </div>

   
 </section>
  );
};

export default NotarialActs;
